.header {
    background: #182933;
    height: 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
}

.header-partner {
    .text {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
    }

    .btn-login {
        width: 95px;
        height: 40px;
        border-radius: 4px !important;
        padding: 10px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        border: 2px solid #FFFFFF !important;
        color: #FFFFFF !important;
        background: transparent !important;
        text-transform: capitalize !important;
        letter-spacing: 1px;
        line-height: 0px !important;
        text-align: center;

        &:hover {
            background: #FFFFFF !important;
            color: #182933 !important;
        }

        &:focus {
            background: #FFFFFF !important;
            color: #182933 !important;
        }
    }

    .btn-white {
        background: #FFFFFF !important;
        color: #182933 !important;

        &:hover {
            color: #FFFFFF !important;
            background: transparent !important;
        }

        &:focus {
            color: #FFFFFF !important;
            background: transparent !important;
        }
    }
}

.text-step {
    color: #182933;
    font-size: 36px;
    font-weight: 700;
}

.step-info {
    .text {
        color: #182933;
        font-size: 24px;
        font-weight: 700;
    }

    .desc {
        color: #808080;
        font-size: 20px;
        font-weight: 500;
    }

    .main-round {
        background-color: #143979;
        width: 33px;
        height: 22px;
        border-radius: 50%;
        text-align: center;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
    }

    .main-heading {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: bold;
        color: #343541;
    }

    .main-description {
        font-family: sans-serif;
        font-size: 12px;
        color: #595959;
        margin-left: 40px;
    }
}


.banner-image-home {
    background-image: url('../../assets/images/landing/banner.png');
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    padding-left: 88px;

    .img-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }

    .muiDescripationHomeInfo {
        font-family: sans-serif;
        font-size: 14px;
        color: #FFFFFF;
    }

    .muiDescripationHomeStoreInfo {
        font-family: sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
    }
}

.img-des {
    margin: 0 30px 0 50px !important;
}

@media (max-width: 1199px) {
    .img-des {
        margin: 0 !important;
    }
}

@media (max-width: 991px) {
    .img-des {
        margin: 0 !important;
    }
}

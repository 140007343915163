.joditEdit {
    .jodit-container {
        .jodit-workplace {
            background: #FFFFFF;

            .jodit-placeholder {
                color: #808080;
            }
        }
    }
}
.product-name {
    .MuiFormHelperText-root.Mui-error {
        color: #FFFFFF !important;
    }

}

.product-modal {
    .MuiFormHelperText-root.Mui-error {
        color: #FFFFFF !important;
    }

}
.paddingRight08 {
    padding-Right: 0.8vw;
    opacity: 0.6;
    transition: 0.5s
}
.paddingRight08:hover {
    opacity: 1
}
.paddingRight0 {
    padding-Right: 0vw;
    opacity: 0.6;
    transition: 0.5s
}

.paddingRight0:hover {
    opacity: 1
}
.iconColorArrow{
   color: #000000;
}
.noMatch {
    margin-left: 10px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.listSectionAddproduct {
    border-collapse: separate;
    border-spacing: 0vw 1vw;
    word-wrap: break-word;
}
.listSectionAddproduct .row div {
    padding-right: 10px;
    padding-left: 10px;
}
.prolisting-color {
    text-align: left;
    height: 3vw;
    width: 5px;
    padding: 0!important;
    margin: 0!important;
}
.set_btn_setting_catlouge{
    text-align: right;
}
.set_btn_setting_catlouge button{
    margin-left: 5px;
    margin-right: 5px;
    min-width: 114px;
}
// .react-switch-bg{
//     background:#1D3762 !important;
// }
.pickerImage{
    width: 35px;
    height: 18px;
    margin-left: 5px;
}
.customerFileBtn #inputGroupFile{
    color:#007bff;
    font-weight: 600;
}
.customerFileBtn .input-group-text {
    background-color: #9c9a9a;
    color: #0a0a0a;
    font-weight: 600;
}

@media screen and (max-width: 1550px) {
    .inventoryIcon {
        .iconColorArrow{
            font-size: 26px !important;
        }
    }

    .inventoryImgUploadIcon {
        height: 35px !important;
        margin-top: -5px;
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .clearance-status{
        margin-left: 60px !important;
    }
}

@media screen and (max-width: 1100px) {
    .inventoryIcon {
        .iconColorArrow{
            font-size: 22px !important;
        }
    }

    .inventoryImgUploadIcon {
        height: 30px !important;
        margin-top: -5px;
    }
}
@media screen and (max-width: 1500px) {
    .screenLeftMargin {
        margin-left: 190px !important;
        overflow: auto;
    }
    .set_btn_setting_catlouge{
        // top: unset !important;
        // margin-top: 20px;
        text-align: left;
        justify-content: unset !important;
    }
    .listSectionAddproduct .col-1{
        padding-left: 5px;
        padding-right: 5px;
    }
    @media screen and (max-width: 1100px){
    .listSectionAddproduct .productItem{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
    .listSectionAddproductdetail .pds {
        // padding-right: 5px;
        padding-left: 5px;
    }
    .listSectionAddproduct .row div,
    .listSectionAddproductdetail{
        word-break: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    .react-switch-handle{
        height: 16px !important; 
        width: 16px !important; 
        // transform: translateX(16px) !important;
    }
    .selected_sett .react-switch-handle{
        transform: translateX(16px) !important;
    }
    .react-switch-bg {
        height: 19px !important; 
        width: 33px !important;
    }
    .ml-2.set_left_padd_small{
        margin-left: 3px !important;
    }
}
#csvFileInput{
    border: 1px solid #1D3762; 
    border-radius: unset;
    // width: 100%;
}
.customerFileBtn{
    width: 300px;
    // width: 50%;
    margin:auto;
}

input[type='file']{
    background-color:transparent;
}

.customerFileBtn input[type='file'] {
    padding:6px 0 0 7px;
    &::-webkit-file-upload-button {
        display: none;
    }
    &::file-selector-button {
        display: none;
    }
}

.customerFileBtn label:hover {
    background-color: #dde0e3;
    cursor: pointer;
}
.customerFileBtn .input-group-text{
    border: unset !important;
    border-radius: unset;
    padding-top: 5px;
}
.customerFileBtn #inputGroupFile{
    outline: unset;
    border-radius: unset;
}
.left_btn_text{
    display: inline-block;
    font-weight: 400;
    color: #fff !important;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #203864 !important;
    border: unset !important;
    padding: 0.375rem 0.75rem;
    font-size: 14px !important;
    line-height: 1.5;
    border-radius: unset;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.upparcase_first{
    text-transform: capitalize;
}


.uploadBox {
    width: 100%;
    height: 50px;

    .imgTagBlock {
        width: 100%; 
        height: 50px; 
        border-radius: 4px; 
        border: 1px solid #000;
    }

    .uploadPhoto {
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #303030;
        border-radius: 4px;

        .uploadInfo {
            text-align: center;
            margin-top: 24px;

            .uploadText {
                margin-top: 5px;
                font-family: "Segoe UI", Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 16px;
            }
        }
    }
}






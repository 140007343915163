.accountInfo {
    width: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 0px 4px 4px 0px !important;
    border-left: 0;
}

.cc {
    border-radius: 4px;
    border: 1px solid #CECECE;
    border-radius: 0px 4px 4px 0px !important;
    border-left: 0px !important;
    height: 45px;
    text-align: end;
    padding: 7px;
    padding-right: 15px;

    img {
        width: 30px;
        height: 30px;
    }
}

.ccIcon {
    margin-top: 1.4vw;
    margin-right: 1vw;
}

.blueText {
    color: #4472C4;
}

.cardNumber {
    border: "2px solid";
    align-items: center;
    padding-right: 10px;
    width: 32vw;
    background-color: #ECECEC;
}

.cardIcon {
    align-items: center;
    display: flex;
    width: 37vw;
    border-radius: 2px !important;
    background-color: #ECECEC !important;
}

.textfieldedituserstripeExpiry {
    width: 100%;
    background: #FFFFFF !important;
    padding: 15px 20px;
    font-family: "Segoe UI", Arial, sans-serif;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0 !important;
}

.textfieldedituserstripeCvv {
    width: 100%;
    background: #FFFFFF !important;
    padding: 15px 20px;
    font-family: "Segoe UI", Arial, sans-serif;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0 !important;
}
.phone-code{
    font-size: 13px !important;
    display: flex;
    align-items: center;
    padding-top: 2px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
}

@media screen and (max-width: 1550px) {
    .textfieldedituserstripeExpiry {
        height: 40px;
    }

    .textfieldedituserstripeCvv {
        height: 40px;
    }

    .cc {
        height: 40px;

        img {
            height: 25px;
            width: 25px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .textfieldedituserstripeExpiry {
        height: 35px;
    }

    .textfieldedituserstripeCvv {
        height: 35px;
    }

    .cc {
        height: 35px;

        img {
            height: 20px;
            width: 20px;
        }
    }

    .accountInfo {
        padding-right: 15px;
        z-index: 1;
    }
}

.rc-tooltip-inner {
    padding: 8px 10px;
    color: #808080 !important;
    text-align: center;
    text-decoration: none;
    background-color: transparent !important;
    border-radius: 6px;
    min-height: 34px;
    font-size: 12px !important;
}


.bank_verified {
    margin-left: 5px;
    background-color: #d4edda;
    color: #155724;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 600;
}

.bank_unverified {
    margin-left: 5px;
    background-color: #f8d7da;
    color: #721c24;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 600;
}
.shipBox {
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.shiptext2 {
    color: #808080;
}

.section1 {
    margin-top: 1.5vw;
    min-height: 23vw;
    background-color: #F2F5F7;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.section2 {
    margin-top: 1.5vw;
    background-color: #F2F5F7;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.section3 {
    margin-top: 1.5vw;
    height: 25vw;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    overflow: scroll;
}

.setting_product_name .col-1 {
    padding-left: 3px;
}

.productInformation {
    margin-top: 2px;
    width: 100%;
    margin-left: 32px;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.historyData {
    height: 22vw;
    overflow: scroll;

    .historyTitle {
        font-size: 16px;
        color: #233949;
    }

    .historyOuter {
        border-left: 2px solid #333;
        margin-left: 15px;

        .historyCard {
            position: relative;
            margin: 0 0 0px 0px;
            padding: 0px 10px;

            .historyInfo {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .title {
                    color: #233949;
                    position: relative;
                    margin-bottom: 0;

                    span {
                        font-size: 10px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: white;
                        border-radius: 999px;
                        left: -16px;
                        top: 5px;
                        border: 3px solid #233949;
                    }
                }
            }
        }
    }
}

.history-tl-container {
    .history-item {
        margin-top: 35px;

        .history-border {
            &:after {
                top: -25px;
                content: "";
                border: 1px solid #E0E0E0;
                width: 10px;
                height: 25px;
                position: absolute;
                left: 50%;
                z-index: 1;
                background: #E0E0E0;
            }

            &:before {
                content: "";
                border: 1px solid #E0E0E0;
                width: 10px;
                height: 25px;
                background: #E0E0E0;
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 40px;
            }

            .history-icon {
                background: #fff;
                width: 50px;
                height: 50px;
                border: 2px solid #b3b2af;
                text-align: center;
                align-items: center;
                border-radius: 50%;
                padding-left: 10px;
                position: absolute;
                z-index: 999;
                top: -5px;
            }

            .history-step {
                font-size: 14px;
                text-align: center;
                color: #666;
                cursor: default;
                margin: 0;
                height: 40px;
                min-width: 85%;
                float: left;
                left: 25px;
                position: relative;
                background-color: #b3b2af;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition: background-color 0.2s ease;

                &:before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    right: -17px;
                    width: 0;
                    height: 0;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 17px solid #b3b2af;
                    z-index: 2;
                    transition: border-color 0.2s ease;
                }
            }
        }

        .OrderReceivedHistory.history-border {
            .history-icon {
                border: 2px solid #fa7f04;
            }

            .history-step {
                background-color: #fa7f04;

                &:before {
                    border-left: 17px solid #fa7f04;
                }
            }
        }

        .OrderinProcessHistory.history-border {
            .history-icon {
                border: 2px solid #FFC000;
            }

            .history-step {
                background-color: #FFC000;

                &:before {
                    border-left: 17px solid #FFC000;
                }
            }
        }

        .OrderReadyHistory.history-border {
            .history-icon {
                border: 2px solid #92D050;
            }

            .history-step {
                background-color: #92D050;

                &:before {
                    border-left: 17px solid #92D050;
                }
            }
        }

        .OrderShippedHistory.history-border {
            .history-icon {
                border: 2px solid #00B050;
            }

            .history-step {
                background-color: #00B050;

                &:before {
                    border-left: 17px solid #00B050;
                }
            }
        }
        .OrderPackedHistory.history-border {
            .history-icon {
                border: 2px solid #1d475c;
            }

            .history-step {
                background-color: #1d475c;

                &:before {
                    border-left: 17px solid #1d475c;
                }
            }
        }

        .OrderCancelledHistory.history-border {
            .history-icon {
                border: 2px solid #FF0000;
            }

            .history-step {
                background-color: #FF0000;

                &:before {
                    border-left: 17px solid #FF0000;
                }
            }
        }

        .OrderCancelledHistory.history-border.PartiallyCancellation {
            .history-icon {
                border: 2px solid #FF0000;
            }

            .history-step {
                background-color: #FF0000;

                &:before {
                    border-left: 17px solid #FF0000;
                }
            }
        }

        .OrderReturnedHistory.history-border {
            .history-icon {
                border: 2px solid #C00000;
            }

            .history-step {
                background-color: #C00000;

                &:before {
                    border-left: 17px solid #C00000;
                }
            }
        }

        .OrderReturnedHistory.history-border.PartiallyReturned {
            .history-icon {
                border: 2px solid #C00000;
            }

            .history-step {
                background-color: #C00000;

                &:before {
                    border-left: 17px solid #C00000;
                }
            }
        }
    }
}

.order-status {
    box-shadow: none;
    border-radius: 0;
    border: none;
    font-size: 18px !important;
    font-weight: 400;
    padding: 5px 10px;
    text-transform: capitalize;
    // color: #fff;
}

.historyLoader {
    .spinnerStyle {
        width: 100%;
        margin-top: 30%;
    }
}

.orderTabs {
    .MuiTabs-root {
        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                border-bottom: 0px;
            }

            .MuiTabs-indicator {
                height: 0px;
            }
        }
    }
}

.pickup-otp {
    // margin-left: auto;
    margin-right: 25px !important;
}

.otp-value {
    margin-top: 38px !important;

    input {
        width: 45px !important;
        height: 45px;
        background: #fff !important;
        border: none !important;
        border: 1px solid #909090 !important;
        border-radius: 0;

        &:focus-visible {
            outline: 0;
        }
    }
}


@media screen and (min-width:900px) {
    .downIcon {
        display: none;
    }

    .arrowDown {
        display: none;
    }
}


@media screen and (max-width: 1550px) {
    .order-status {
        font-size: 16px !important;
    }

    .downIcon {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .history-tl-container {
        .history-item {
            margin-top: 20px;

            .history-border {
                &:after {
                    top: -20px;
                    height: 20px;
                }

                &:before {
                    height: 20px;
                    top: 35px;
                }

                .history-step {
                    height: 35px;

                    &:before {
                        right: -17px;
                        border-top: 17px solid transparent;
                        border-bottom: 18px solid transparent;
                    }
                }

                .history-icon {
                    width: 40px;
                    height: 40px;
                    top: -2px;
                    padding-left: 8px;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .order_receive_box>div {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 1400px) {
    .history-tl-container {
        .history-item {
            .history-border {
                &:after {
                    left: 55%;
                }

                &:before {
                    left: 55%;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .history-tl-container {
        .history-item {
            margin-top: 20px;

            .orderhistoryDate {
                padding-right: 0px !important;
            }

            .history-border {
                &:after {
                    top: -15px;
                    height: 15px;
                }

                &:before {
                    height: 15px;
                    top: 30px;
                }

                .history-step {
                    height: 30px;

                    &:before {
                        right: -17px;
                        border-top: 15px solid transparent;
                        border-bottom: 15px solid transparent;
                    }
                }

                .history-icon {
                    width: 40px;
                    height: 40px;
                    padding-left: 8px;
                    top: -4px;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .btn-primary {
        font-size: 12px !important;
    }

    .btn-cancle {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 1199px) {
    .mobile-top-set {
        padding-top: 18px;
    }
}

@media screen and (max-width: 991px) {
    .screenLeftMargin {
        margin-left: 200px !important;
    }

    .small_payrece {
        margin-top: 18px;
    }
}

@media screen and (max-width: 810px) {
    .left_img_screen {
        display: block !important;
    }
}

@media screen and (max-width: 605px) {
    .mobile-top-set {
        padding-top: 18px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .MuiTabs-root {
        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                width: 50vw;
                padding-left: 0px !important;
                margin-left: 15px !important;
            }

            .MuiTabs-indicator {
                height: 0px;
            }
        }
    }

    .MuiTabs-root .MuiTabs-fixed .MuiTabs-flexContainer .MuiTab-root {
        min-width: 70px;
    }

    .productInformation {
        margin-left: 15px;
        width: 57vw;
        height: 100vh;
    }

    .downIcon {
        margin-top: 810px;
        margin-left: 25px;
        background-color: #962f7b;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;

        animation: gototop 1s linear infinite alternate-reverse;

        @keyframes gototop {
            0% {
                transform: translateY(-0.4rem);
            }

            100% {
                transform: translateY(0.2rem);
            }

        }
    }

    .arrowDown {
        display: none;
    }

    .section1 {
        margin-top: 25px !important;
        margin-left: 15px !important;
        height: 30vh !important;
    }

    .section2 {
        margin-left: 15px !important;
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }

    .section3 {
        height: 20vh;
        margin: 15px 0 25px 15px !important;
    }
}

@media only screen and (width : 1024px) and (height: 768px) {
    .productInformation {
        width: 67vw;
        margin-left: 23px;
        height: 128vh;
    }

    .arrowDown {
        margin-top: 565px;
        margin-left: 25px;
        background-color: #962f7b;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        height: 32px;
        animation: gototop 1s linear infinite alternate-reverse;

        @keyframes gototop {
            0% {
                transform: translateY(-0.4rem);
            }

            100% {
                transform: translateY(0.1rem);
            }

        }
    }

    .downIcon {
        display: none;
    }

    .MuiTabs-root {
        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                width: 50vw;
                padding-left: 0px !important;
                margin-left: 23px !important;
            }

            .MuiTabs-indicator {
                height: 0px;
            }
        }
    }

    .MuiTabs-root .MuiTabs-fixed .MuiTabs-flexContainer .MuiTab-root {
        min-width: 80px;
    }

    .section1 {
        margin-top: 25px !important;
        margin-left: 23px !important;
        height: 40vh !important;
    }

    .section2 {
        margin-left: 23px !important;
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }

    .section3 {
        height: 35vh;
        margin: 15px 0 25px 23px !important;
    }
}
.reviewFinancial {
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #606060;
}
.bank {
    padding: 7px 15px;
    background-color: #FFFFFF;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0;

    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}

.storeTypeReview {
    vertical-align: middle;
    max-height: 100px;
    max-width: 100px;
}

.textfieldedituserstripe {
    width: 100%;
    background: #FFFFFF !important;
    padding: 15px 20px;
    font-family: "Segoe UI", Arial, sans-serif;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0 !important;
}

.textfieldedituserstripeInRow {
    width: 100%;
    background: #FFFFFF !important;
    padding: 15px 20px;
    font-family: "Segoe UI", Arial, sans-serif;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #C0C0C0 !important;
}

.flexrows {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 32vw
}




@media screen and (max-width: 1550px) {
    .textfieldedituserstripe {
        height: 40px;
    }

    .bank {
        height: 40px;
        padding: 6px 15px;

        img {
            width: 25px;
            height: 25px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .textfieldedituserstripe {
        height: 35px;
        padding: 12px 15px;
    }

    .bank {
        height: 35px;
        padding: 6px 15px;

        img {
            width: 20px;
            height: 20px;
        }
    }
}
.locationSection{
    padding-top: 10px;
    display:flex
}
hr {
    margin-top: 0rem !important;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-right: 0% !important;
}
.Button {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}
.counter {
    width: 25px;
    height: 25px;
    border: 1px solid #233949;
    position: absolute;
    color: #FFFFFF;
    background-color: #233949;
    border-radius: 50%;
    text-align: center;
    margin-left: 1rem;
    margin-top: -15px;
}

.hideper {
    display: none;
}

.showper {
    justify-content: center;
    align-items: center;
}

.inventoryBox {
    margin: 15px 15px 0;
    padding: 10px;
    box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.inventoryColorPicker {
    .sketch-picker  {
        width: 100% !important;
        padding: 10px !important;
        box-sizing: inherit !important;
    }
}

.deleteUploadedImage {
    width: 26px;
    height: 26px;
    position: absolute;
    color: #FFFFFF;
    border-radius: 50%;
    margin-left: 75px !important;
    margin-top: -12px !important;
    background: #000;
    padding-top: 2px;
}
.deleteModalImage{
    width: 26px;
    height: 26px;
    position: absolute;
    color: #FFFFFF;
    border-radius: 50%;
    // margin-left: 410px !important;
    margin-top: -12px !important;
    // background: #000;
    padding-top: 2px;
} 

// @media screen and (max-width: 1200px) {
//     .deleteModalImage{ 
//         margin-left: 400px !important
//     }   
// }
@media screen and  (max-width: 1100px) {
    .deleteModalImage{
        margin-left: 75px !important;
    } 
}


.frameTextWrap {
    white-space: nowrap;
    text-align: center;
    background: #ECECEC;
    border-radius: 4px;
    margin-top: 1%;
}

.storeType {
    width: 65px;
    height: 65px;
    background: #ECECEC;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;

    .cardName {
        margin-top: 5px;
        font-family: "Segoe UI", Arial, sans-serif;
        font-weight: 400;
        color: #808080;
        text-align: center;
        white-space: break-spaces;
        font-size: 12px !important;
    }

    .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    img {
        vertical-align: middle;
        height: 30px;
        width: 30px;
    }

    .cardNameSelected {
        margin-top: 5px;
        font-family: "Segoe UI", Arial, sans-serif;
        font-weight: 700;
        color: black;
        text-align: center;
        white-space: break-spaces;
        font-size: 12px !important;
    }

    .selectedGrid {
        position: absolute;
        border-left: 16px solid transparent;
        border-right: 20px solid #233949;
        border-bottom: 20px solid transparent;
        padding-right: 10px;
        display: inline-block;
    }
}

.storeTypeDisable {
    width: 65px;
    height: 65px;
    background: #525252;
    border-radius: 4px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;

    .cardNameSelected {
        margin-top: 5px;
        font-family: "Segoe UI", Arial, sans-serif;
        font-weight: 700;
        color: black;
        text-align: center;
        white-space: break-spaces;
        font-size: 14px !important;
    }
}

.onlineOffline {
    font-family: "Roboto-Regular";
    font-weight: 500;
    font-size: 30px;
    color: #233949;
}

.selectedTriangle {
    position: absolute;
    width: 22px;
    height: 24px;
    border-left: 20px solid transparent;
    border-right: 20px solid #233949;
    border-bottom: 20px solid transparent;
    height: 0;
    width: 0;
}

.trangle {
    position: absolute;
    border-left: 18px solid transparent;
    border-right: 20px solid #233949;
    border-bottom: 20px solid transparent;
    height: 0;
    width: 0;
    padding-right: 20px;
    display: inline-block
}

@media screen and (max-width: 1550px) {
    .storeType {
        width: 55px;
        height: 55px;

        .selectedGrid {
            padding-right: 8px;
            border-left: 14px solid transparent;
            border-right: 18px solid #233949;
            border-bottom: 18px solid transparent;
        }

        img {
            height: 25px;
            width: 25px;
        }

        .cardName {
            font-size: 10px !important;
        }

        .cardNameSelected {
            font-size: 10px !important;
        }
    }

    .storeTypeDisable {
        width: 55px;
        height: 55px;

        img {
            height: 25px;
            width: 25px;
        }

        .cardNameSelected {
            font-size: 10px !important;
        }
    }
}

@media screen and (max-width: 1100px) {
    .storeType {
        width: 50px;
        height: 50px;

        img {
            height: 20px;
            width: 20px;
        }

        .selectedGrid {
            padding-right: 6px;
            border-left: 12px solid transparent;
            border-right: 16px solid #233949;
            border-bottom: 16px solid transparent;
        }
    }

    .storeTypeDisable {
        width: 50px;
        height: 50px;

        img {
            height: 20px;
            width: 20px;
        }
    }
}

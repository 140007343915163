.dsWhiteBox {
	background: #FFFFFF;
	box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);

	.box-title {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		color: #878787;
		font-family: "Segoe UI", Arial, sans-serif !important;
	}

	.box-total {
		font-weight: 500;
		font-size: 40px;
		line-height: 40px;
		color: #1A2934;
		font-family: "Segoe UI", Arial, sans-serif !important;

		.box-up-percentage {
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: #12872C;
			flex: none;
			order: 1;
			flex-grow: 0;
			font-family: "Segoe UI", Arial, sans-serif !important;
		}

		.box-down-percentage {
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: #F13636;
			flex: none;
			order: 1;
			flex-grow: 0;
			font-family: "Segoe UI", Arial, sans-serif !important;
		}
	}
}

.dsDarkBox {
	background: #1A2934;
	box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);

	.box-title {
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		color: #FFFFFF;
		font-family: "Segoe UI", Arial, sans-serif !important;
	}

	.box-total {
		font-weight: 500;
		font-size: 40px;
		line-height: 40px;
		color: #FFFFFF;
		font-family: "Segoe UI", Arial, sans-serif !important;

		.box-up-percentage {
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: #09A92B;
			flex: none;
			order: 1;
			flex-grow: 0;
			font-family: "Segoe UI", Arial, sans-serif !important;
		}

		.box-down-percentage {
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: #F13636;
			flex: none;
			order: 1;
			flex-grow: 0;
			font-family: "Segoe UI", Arial, sans-serif !important;
		}
	}
}
.home_setting svg{
	max-width: inherit;
}
.modalonhold{
	font-size: 18px;
	font-weight: 600;
}
@media screen and (max-width: 1250px) {
	.dsWhiteBox .box-total{
		font-size: 30px;
	}
}
@media screen and (max-width: 1100px) {
	.dsWhiteBox .box-total{
		font-size: 25px;
	}
}
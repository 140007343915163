.createBusinessDeal {
    vertical-align: middle;
    background: #233949;;
    text-align: center;
    padding-right: 10vh;
    min-height: 100vh;
}
.logo{
    position: relative;
    top: 30%;
}
.logo_text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    color: #858585;
}
.create_card_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: absolute;
}
.create_otp_card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;
    margin-left: -30px;
    margin-top: 90px;
    margin-bottom: 90px;
    // box-shadow: 0px 5px 24px rgba(201, 201, 201, 0.5);
    position: absolute;
    // right: 50px;
    // height: 50rem;

    .otp-input {
        input {
            width: 45px !important;
            height: 45px; 
            background: #fff !important;
            border: none !important;
            border: 2px solid #909090 !important; 
            // border-bottom: 1px solid #ECECEC !important;
            border-radius: 0;
            margin-left: 10px;

            &:focus-visible {
                outline: 0;
            }
        }
    }
}


#create-card{
    position: relative;
}
.back_arrow{
    color: #fff;
}
.back{
    padding-top: 5vw;
    padding-right: 5vw;
    cursor: pointer;
}
.flagSize{
    width: 30px;
    height: 18px;
}
.location_icon{
    position: absolute;
    margin-left: 22vw;
}

.inputLocationIcon {
    background: #FFF;
    height: 45px;
    border: 1px solid #C0C0C0;
    border-radius: 0px 4px 4px 0px !important;
    border-left: 0px !important;
    padding: 12px 0px;
}

.geosuggest-left-top-bottom {
    .geosuggest {
        .geosuggest__input-wrapper {
            .geosuggest__input {
                border-radius: 4px 0px 0px 4px !important;
                border-right: 0px !important;
            }
        }
    }
}
.Geosuggest_setting{
    padding-left: 15px;
    width: calc(100% - 50px);
}
.geosuggest__suggests-wrapper{
    width: calc(100% - 105px);
}
.Geosuggest_main_setting{
    width: calc(100% + 37px);
}
.MuiInputBase-root.country_changes{
    width: 86px;
    display: inline-block;
    position: absolute;
    left: 0px;
    border-radius: 5px 0px 0px 5px;
    border-right: 0px;
}
.phone_attach .MuiFormControl-root .MuiInputBase-root{
    border-radius: 0px 5px 5px 0px;
}
.MuiFormControl-root .MuiInputBase-root.country_changes .MuiSelect-nativeInput{
    border-right: 0px;
}
.phone_attach{
    width: calc(100% - 85px);
    display: inline-block;
    position: absolute;
    right: 0px;
}
.MuiFormControl-root .MuiInputBase-root{
    padding: 8px;
    padding-left: 12px;
}

.inputLocationIcon {
    width: 25px;
}
.create_card_container.create_card_container {
    // margin-top: 5%;
    margin-bottom: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 80px;
}

.create_card_container.create_card_container .text_mng.submit_div{
    margin-bottom: 0px;
    margin-top: 30px;
}
.create_card_container .text_mng{
    width: 38vw;
    margin-bottom: 30px;
}
.phone-country{
    font-size: 14px;
    display: flex;
    align-items:center;
    padding-left: 5px;
    padding-right: 5px;
}


@media screen and (max-width: 1550px) {
    .Geosuggest_setting{
        width: calc(100% - 45px);
    }
    .geosuggest__suggests-wrapper{
        width: calc(100% - 99px);
    }
    .create_card_container {
        // margin-top: 15%;
        margin-bottom: 15%;
    }
    .inputLocationIcon {
        // height: 40px;
        padding: 8px 0px;
    }
    .create_card_container.create_card_container{
        margin-left: 25px;
    }
}
@media screen and (max-width: 1100px) {
    .inputLocationIcon {
        height: 35px;
        padding: 6px 0px;

        img {
            margin-left: -15px;
        }
    }
    .create_card_container.create_card_container{
        margin-left: 25px;
    }
}
@media screen and (max-width: 991px){
    .inputLocationIcon {
        height: 35px;
        padding: 6px 0px;

        img {
            margin-left: -15px;
        }
    }
    .create_card_container.create_card_container{
        margin-left: 0;
    }
    .text_mng.first_div_top {
        width: 50vw;
    }
    .create_card_container .text_mng{
        width: 50vw;
        margin-bottom: 30px;
    }
}


.tell-us-header {
    .title {
        color: #000000;
        font-size: 24px;
        font-weight: 700;
    }
    .desc {
        color: #707070;
        font-size: 12px;
        font-weight: 400;
    }
}